import React from 'react';

import Layout from '../../components/layout';
import Error from '../../components/widgets/error';

const OopsPage = ({location}) => {

  let error;

  if (location && location.state) {
    error = location.state.error;
  }
  
  return (
  <Layout>
    <Error error={error}/>
  </Layout>
)}

export default OopsPage;